import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Privacy.scss";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <Container fluid className="privacy-policy-container my-4 ">
      <Row className="justify-content-center row-container">
        <Col md={8} lg={6}>
          <Card className="privacy-policy-card">
            <Card.Body>
              <Card.Title className="text-center mb-4">
                Privacy Policy
              </Card.Title>
              <Card.Text className="card-text">
                <h5>Who We Are</h5>
                <p>
                  Our website address is:
                  <Link
                    to="http://mentationtech.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    http://mentationtech.in
                  </Link>
                </p>

                <h4>What Personal Data We Collect and Why We Collect It</h4>
                <h6>Comments</h6>
                <p>
                  When visitors leave comments on the site we collect the data
                  shown in the comments form, and also the visitor’s IP address
                  and browser user agent string to help spam detection. An
                  anonymized string created from your email address (also called
                  a hash) may be provided to the Gravatar service to see if you
                  are using it. The Gravatar service privacy policy is available{" "}
                  <a
                    href="https://automattic.com/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link m-2"
                  >
                    [ here ]
                  </a>
                  . After approval of your comment, your profile picture is
                  visible to the public in the context of your comment.
                </p>

                <h6>Media</h6>
                <p>
                  If you upload images to the website, you should avoid
                  uploading images with embedded location data (EXIF GPS)
                  included. Visitors to the website can download and extract any
                  location data from images on the website.
                </p>

                <h6>Contact Forms</h6>
                <p>
                  We collect personal data through contact forms to respond to
                  your inquiries and provide support.
                </p>

                <h6>Cookies</h6>
                <p>
                  If you leave a comment on our site you may opt-in to saving
                  your name, email address, and website in cookies. These are
                  for your convenience so that you do not have to fill in your
                  details again when you leave another comment. These cookies
                  will last for one year.
                </p>
                <p>
                  If you visit our login page, we will set a temporary cookie to
                  determine if your browser accepts cookies. This cookie
                  contains no personal data and is discarded when you close your
                  browser.
                </p>
                <p>
                  When you log in, we will also set up several cookies to save
                  your login information and your screen display choices. Login
                  cookies last for two days, and screen options cookies last for
                  a year. If you select “Remember Me,” your login will persist
                  for two weeks. If you log out of your account, the login
                  cookies will be removed.
                </p>
                <p>
                  If you edit or publish an article, an additional cookie will
                  be saved in your browser. This cookie includes no personal
                  data and simply indicates the post ID of the article you just
                  edited. It expires after 1 day.
                </p>

                <h6>Embedded Content from Other Websites</h6>
                <p>
                  Articles on this site may include embedded content (e.g.,
                  videos, images, articles, etc.). Embedded content from other
                  websites behaves in the exact same way as if the visitor has
                  visited the other website.
                </p>

                <h6>Analytics</h6>
                <p>
                  We use analytics tools to collect data on user interactions to
                  improve our services.
                </p>

                <h5>Who We Share Your Data With</h5>
                <p>
                  We do not share your personal data with third parties except
                  as necessary to fulfill your requests and as required by law.
                </p>

                <h5>How Long We Retain Your Data</h5>
                <p>
                  If you leave a comment, the comment and its metadata are
                  retained indefinitely. This is so we can recognize and approve
                  any follow-up comments automatically instead of holding them
                  in a moderation queue.
                </p>
                <p>
                  For users that register on our website (if any), we also store
                  the personal information they provide in their user profile.
                  All users can see, edit, or delete their personal information
                  at any time (except they cannot change their username).
                  Website administrators can also see and edit that information.
                </p>

                <h5>What Rights You Have Over Your Data</h5>
                <p>
                  If you have an account on this site or have left comments, you
                  can request to receive an exported file of the personal data
                  we hold about you, including any data you have provided to us.
                  You can also request that we erase any personal data we hold
                  about you. This does not include any data we are obliged to
                  keep for administrative, legal, or security purposes.
                </p>

                <h5>Where We Send Your Data</h5>
                <p>
                  Visitor comments may be checked through an automated spam
                  detection service.
                </p>

                <h5>Your Contact Information</h5>
                <p>You can contact us at [insert contact information here].</p>

                <h5>Additional Information</h5>
                <h6>How We Protect Your Data</h6>
                <p>
                  We implement security measures to protect your personal data
                  from unauthorized access, alteration, or destruction.
                </p>

                <h6>What Data Breach Procedures We Have in Place</h6>
                <p>
                  In the event of a data breach, we will notify affected users
                  and take appropriate measures to secure the data.
                </p>

                <h6>What Third Parties We Receive Data From</h6>
                <p>
                  We may receive data from third-party services as part of our
                  analytics and advertising efforts.
                </p>

                <h6>
                  What Automated Decision Making and/or Profiling We Do with
                  User Data
                </h6>
                <p>
                  We do not use automated decision-making or profiling based on
                  user data.
                </p>

                <h6>Industry Regulatory Disclosure Requirements</h6>
                <p>
                  We comply with industry regulations and standards for data
                  protection and privacy.
                </p>
                <h6>Refunds and Cancellations Policy</h6>
                <p>
                  At Mentation Technologies, we prioritize customer satisfaction
                  and aim to provide a seamless experience. Our Refund and
                  Cancellation policy has been designed to align with the
                  products and services offered on our website. Below are the
                  terms and conditions for refunds and cancellations:
                </p>
                <h6>Cancellation Policy</h6>
                <a>
                  <li>Order Cancellation:</li>
                </a>
                <p>
                  Cancellations are permitted only if requested before the order
                  is processed or dispatched. To cancel your order, please
                  contact our support team immediately via
                  supports@mentationgroup.com.
                </p>
                <a>
                  <li>Service Cancellation:</li>
                </a>
                <p>
                  For services booked through our platform, cancellations must
                  be made at least 12hrs before the scheduled service
                  time. Late cancellations may incur a fee as per the service
                  terms.
                </p>
                <h6>Refund Policy</h6>
                <a>
                  <li>Refund Eligibility:</li>
                </a>
                <p>
                  Refunds are applicable only for eligible cancellations or if
                  the delivered product/service is defective or does not meet
                  the stated specifications. Requests for refunds must be
                  submitted within 10 Days of delivery/service completion.
                </p>
                <a>
                  <li>Refund Processing:</li>
                </a>
                <p>
                  Once your refund request is approved, we will process the
                  refund within 5-7 working days. The refunded amount will be
                  credited to your original payment method or bank account, as
                  per your preference.
                </p>
                <a>
                  <li>Partial Refunds:</li>
                </a>
                <p>
                  In certain cases, partial refunds may be issued, subject to an
                  assessment of the situation.
                </p>
                <h6>How to Request a Refund or Cancellation</h6>
                <p>
                  Reach out to us at supports@mentationgroup.com with your order
                  ID or service reference number. Provide details of your
                  request, including reasons for cancellation or refund.
                </p>
                <h6>Exclusions</h6>
                <p>
                  Certain products or services may be non-refundable or
                  non-cancellable due to their nature (e.g., digital goods,
                  perishable items, or customized services). Please review the
                  product/service-specific terms before making a purchase.
                </p>
                <p>
                  For any further inquiries or assistance, feel free to contact
                  our customer support team. We’re here to help!
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
